<template>
  <a-drawer
      :bodyStyle="{padding:'0'}"
      :closeIcon="null"
      :mask="false"
      :maskClosable="false"
      :visible="open"
      height="100%"
      placement="top"
      title=""
      width="100%"
      @close="onClose"
  >
    <div class="navs">
      <div class="nav">
        <!--                <div class="nav_line " :class="selectedKeys === 1?'nav_line_active':''"></div>-->
        <div class="nav_title">
          <router-link to="/index">
            首页
            <down-outlined/>
          </router-link>
        </div>
      </div>
      <div class="nav">
        <!--                <div class="nav_line" :class="selectedKeys === 2?'nav_line_active':''"></div>-->
        <div class="nav_title">
          <router-link to="/introduction">
            公司简介
            <down-outlined/>
          </router-link>
        </div>
      </div>
      <div class="nav">
        <!--                <div class="nav_line" :class="selectedKeys === 3?'nav_line_active':''"></div>-->
        <div class="nav_title">
          <router-link to="/product">
            产品中心
            <down-outlined/>
          </router-link>
        </div>
      </div>
      <div class="nav">
        <!--                <div class="nav_line" :class="selectedKeys === 4?'nav_line_active':''"></div>-->
        <div class="nav_title">
          <router-link to="/exhibition">
            展览回顾
            <down-outlined/>
          </router-link>
        </div>
      </div>
      <div class="nav">
        <!--                <div class="nav_line" :class="selectedKeys === 5?'nav_line_active':''"></div>-->
        <div class="nav_title">
          <router-link to="/about">
            联系我们
            <down-outlined/>
          </router-link>
        </div>
      </div>
    </div>
    <div class="index_search_par" :style="{marginBottom:big?'750px':'340px'}">
      <div class="search_title">
        <span>搜索中心</span>
      </div>
      <div class="search_line">
        <div class="search_box">
          <input id="search" v-model="searchValue" placeholder="  输入您想搜索的内容···">
        </div>
        <div class="search_button shou">
          <img src="@/assets/img/search_button.png" @click="searchButton">
        </div>
      </div>
      <div class="search_decoration">
        不仅仅是供应商，还是您的顾问！
      </div>
      <div class="search_result_par">
        <div v-for="(i,k) in searchResult" v-bind:key="k" class="search_result "
             @click="jumpToDetails(i.id)">
          <span class="search_result_left shou"><span class="big">{{ i.product_sn }}</span><span
              class="small">{{ i.product_type }}</span></span>
          <span class="search_result_left shou"><span class="big">{{ i.product_nickname }}</span><span
              class="small"><setting-outlined/>{{ i?.product_subtype }}</span></span>
        </div>

      </div>

    </div>

    <div class="footer ">
      <div class="body">

        <div class="footer_span">
          <div class="footer_logo">
            <img src="@/assets/img/footer_bac.png">
          </div>
          <span>
        意大利水智安阀芯公司杭州代表处：
      </span>
          <span>
              杭州市杭大路15号嘉华国际1116室
          </span>
          <span>
       电话:0571 87632309 87632306
      </span>
          <span class="footer-spase">
            传真:0571 87632307  E-mail:info@stsr.biz
        </span>
          <span>
        S.T.S.R. S.r.l. -Via Milano,
      </span>
          <span>
              7/5 - 20084 - Lacchiarella (MI) - Italy
          </span>
          <span>
        Tel. +39 02 90030656 - +39 02 90076832 -
      </span>
          <span>
              Fax.+39 02 9007569 - E-mail. info@stsr.it
          </span>
        </div>
      </div>

    </div>

    <template #extra>
      <div class="draw-close">
        <close-outlined @click="onClose"/>
      </div>
    </template>
  </a-drawer>
  <div class="overflow_hide">
    <div class="nav_par body">
      <div class="logo ">
        <img src="@/assets/img/nav_logo.png">
      </div>

      <div class="nav_s_p">
                <span class="menu-icon" @click="openDraw">
                    <MenuOutlined/>
                </span>

        <div class="flag shou">
          <img src="@/assets/img/nav_flag.png" @click="jumpToWeb">
        </div>
      </div>
      <div class="nav-title">
        {{ navArray[selectedKeys] }}
      </div>
    </div>
  </div>

  <div :style="{paddingBottom:big?'100px':'0'}">
    <router-view></router-view>
  </div>
  <div class="footer " >
    <div class="body">

      <div class="footer_span">
        <div class="footer_logo">
          <img src="@/assets/img/footer_bac.png">
        </div>
        <span>
                    意大利水智安阀芯公司杭州代表处：
                </span>
        <span>
                    杭州市杭大路15号嘉华国际1116室
                </span>
        <span style="display: flex">
                    电话:
                    <span style="display: flex;    flex-direction: column-reverse;">
                        <span>0571 87632309</span>
                        <span>0571 87632307</span>
                    </span>
                </span>
        <span>
                    传真:0571 87632307
                </span>
        <span class="footer-spase">
                    E-mail:info@stsr.biz
                </span>
        <span>
                     S.T.S.R. S.r.l. -Via Milano,
                </span>
        <span>
                    7/5 - 20084 - Lacchiarella (MI) - Italy
                </span>
        <span style="display: flex">
                    Tel.
                    <span style="display: flex;    flex-direction: column-reverse;">
                        <span>+39 02 90030656 -</span>
                        <span>+39 02 90076832 -</span>
                    </span>
                </span>

        <span>
                    Fax.+39 02 9007569 -
                </span>
        <span>
                    E-mail. info@stsr.it
                </span>
      </div>
    </div>

  </div>

</template>

<script>
import {ref} from 'vue';
import {MenuOutlined, CloseOutlined, DownOutlined, SettingOutlined} from '@ant-design/icons-vue';

import request from "umi-request";

export default {
  name: 'App',
  data() {
    return {
      open: false,
      searchResult: [],
      searchValue: '',
      selectedKeys: ref(0),
      navArray: ["首页", "首页", "公司简介", "产品中心", "展览回顾", "联系我们"],
      big: false
    }
  },
  components: {
    SettingOutlined,
    MenuOutlined,
    CloseOutlined,
    DownOutlined
  },
  mounted() {
  },
  created() {
    let that = this


        if (document.addEventListener) {
          document.addEventListener("WeixinJSBridgeReady", handleFontSize, false);
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", handleFontSize);
          document.attachEvent("onWeixinJSBridgeReady", handleFontSize);
        }


      function handleFontSize() {
        that.big = true
      }

  },
  methods: {
    jumpToDetails(id) {
      console.log(id)
      this.$router.push({
        name: 'productInfo',
        query: {id: id}
      })
    },
    searchButton: function () {
      let _this = this
      this.get("query", this.searchValue, function (e) {
        _this.searchResult = e.data
      })
    },
    get: function (query, name, handleFun) {
      // let folderName = mes||"upload"
      request.get('/api/' + query, {
        params: {
          name: name,
        },
      }).then(handleFun).catch((e) => {
        console.log(e)
      });
    },
    openDraw() {
      this.open = true
    },
    onClose() {
      this.open = false
    },
    jumpToWeb() {
      window.open("http://www.stsr.it/")
    },
  },
  watch: {
    searchValue: function (v, o) {
      let _this = this;
      if (o.trim() !== v.trim()) {
        this.get("query", v, function (e) {
          _this.searchResult = e.data
        })
      }
    },
    '$route': function () {
      console.log(this.$route.path)
      switch (this.$route.path) {
        case "/":
        case "/index":
          this.selectedKeys = ref(1)
          break;
        case "/introduction":
          this.selectedKeys = ref(2)
          break;
        case "/product":
        case "/productInfo":
          this.selectedKeys = ref(3)
          break;
        case "/exhibition":
          this.selectedKeys = ref(4)
          break;
        case "/about":
          this.selectedKeys = ref(5)
          break;
      }
      this.open = false

    },
  }
}
</script>

<style>

.shou {
  cursor: pointer;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  zoom: .8;
}

html, body {
  width: 100%;
  height: auto !important;
}

html {
  min-height: 100%;
  position: relative;
}

#app {
  padding-bottom: 230px;
  width: 100%;
  overflow-x: hidden;
}

.body {
  margin: 0 auto;

}

.nav_title a {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu-icon {
  font-size: 32px;
  color: white;
  display: flex;
  align-items: center;
}

.menu-icon span {
  padding: 2px;
  background: #2A4982;
}

.overflow_hide {
  width: 100%;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
}

.nav_par {
  height: 70px;
  position: relative;
  background: url("@/assets/img/nav_bac.png") no-repeat 100% 100%;
  background-size: cover;
  background-position: left;
  display: flex;
  justify-content: space-between;
}

.nav-title {
  display: inline-block;
  width: 100%;
  height: 100%;
  text-align: center;
  color: white;
  line-height: 70px;
  position: absolute;
  font-size: 18px;
  letter-spacing: 1px;
  left: 0;
  z-index: 2;
  bottom: 0;
}

.logo img {
  height: 70px;
  width: 80px;
  margin-left: 80px;
}

.draw-close {
  font-size: 30px;
}

.navs {
  display: flex;
  flex-direction: column;
}

.nav {
  margin-top: 10px;
  padding: 0 20px 10px 20px;
  font-size: 20px;
  border-bottom: 1px solid #F0F0F0;

}

.nav a {
  color: #303030;
}

.nav_line {
  background: white;
  border-radius: 10px;
  height: 2px;
}

.nav_line_active {
  background: #C1272D;
}

.nav_s_p {
  display: flex;
  z-index: 9;
}

.flag {
  margin: 0 20px 0 50px;
  height: 50px;
}

.flag img {
  /*width: 40px;*/
  height: 50px;
}


.footer {
//height: 250px; background: #052665; position: absolute; bottom: 0;
  width: 100%;
}

.footer_logo {
  position: absolute;
  top: -70px;
  left: -60px;
}

.footer_logo img {
  width: 90px;
  height: 90px;
}

.footer_span span {
  display: inherit;
  color: white;
  font-size: 14px;
  font-weight: lighter;
  letter-spacing: 2px;
}

.footer .body {
  /*margin-top: 50px;*/
  display: flex;
  justify-content: space-around;
  width: calc(100% - 130px);
}

.footer_span {
  margin-top: 20px;
  position: relative;
  margin-bottom: 20px;
}

.index_search_par {
  width: 90%;
  margin: 40px auto 340px;
}

.search_title {
  font-size: 24px;
  font-weight: 200;
  margin-bottom: 20px;
  color: black;
}

.search_title span {
  position: relative;
  z-index: 2;
}

.search_title span::before {
  content: "";
  display: inline-block;
  width: 100%;
  position: absolute;
  height: 10px;
  background: rgba(193, 39, 45, 0.59);
  left: 0;
  z-index: 1;
  bottom: 0;
}

.search_line {
  display: flex;
  justify-content: space-between;
}

.search_box input {
  border: none;
  box-shadow: none;
  outline: none;
  width: 80%;
  height: 30px;
  caret-color: #C1272D;
}

.search_box input::placeholder {
  color: #C1272D;
  font-size: 12px;
  font-weight: lighter;
  letter-spacing: 3px;
}

.search_box {
  border: 1px #C1272D solid;
  padding: 10px 10px;
  width: 75%;
}

.search_button {
  width: 100px;
}

.search_decoration {
  color: #666666;
  font-size: 14px;
  font-weight: lighter;
  letter-spacing: 2px;
  margin: 20px 0px 5px;
}

.search_decoration_line {
  background: black;
  width: 55px;
  height: 2px;
}

.search_result {
  display: flex;
}

.search_result_left {
  font-size: 20px;
  margin-bottom: 14px;

  display: flex;
  flex-direction: column;
}

.search_result_left .small {
  font-size: 14px;
  font-weight: lighter;
  padding-top: 5px;
  padding-right: 20px;
  letter-spacing: 2px;
}

.search_result_left .small span {
  margin-right: 5px;
}

.search_result_left .big {
  border-bottom: 2px #f9e9e9 solid;
  padding-right: 20px;
}

.footer-spase {
  margin-bottom: 20px;
}

@font-face {
  font-family: 'FZZ';
  src: url("@/assets/font/PingFang.ttc");
}

@media screen and (max-width: 400px) {
  .index_search_par {
    margin-top: 10px;
  }

  .nav {
    font-size: 18px;
  }

  .footer_span span {
    font-size: 13px;
  }

  .footer-spase {
    margin-bottom: 10px;
  }

  .flag {
    margin-left: 30px;
  }
}

@media screen and (max-width: 300px) {
  .search_result_left {
    font-size: 26px;
  }

  .index_search_par {
    margin-top: 5px;
  }

  .nav {
    font-size: 16px;
  }

  .footer_span span {
    font-size: 12px;
    letter-spacing: 1px;
  }

  .footer_logo img {
    width: 80px;
    height: 80px;
  }

  .footer-spase {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 260px) {
  .footer_span span {
    font-size: 10px;
  }
}
</style>
